export const DO_URI = 'nyc3.digitaloceanspaces.com';
export const DO_ORIGIN_URL = `https://${
  import.meta.env.VITE_DO_BUCKET_NAME
}.nyc3.digitaloceanspaces.com`;
export const DO_CDN_URL = `https://${
  import.meta.env.VITE_DO_BUCKET_NAME
}.nyc3.cdn.digitaloceanspaces.com`;
export const SUPPORT_EMAIL = 'support@litefarm.org';

// Changing this forces logout and updates the new release card
export const APP_VERSION = '3.6.5';
export const VERSION_RELEASE_NOTES_LINK =
  'https://www.litefarm.org/post/exciting-improvements-enhanced-soil-amendment-task-management-in-litefarm';

export const CONSENT_VERSION = '6.0';
